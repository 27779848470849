.contactus {
  background-image: url(../images/contactImage.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 0 80px 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
    z-index: 0;
    background: rgba(187, 68, 0, 0.8) !important;
  }
  &__container {
    position: relative;
    z-index: 5;
    &__row {
      &__col {
        &__title {
          font-size: 40px;
          color: #fff;
          margin-bottom: 8px;
        }
        &__desc {
          font-size: 18px;
          font-family: "Nunito", sans-serif;
          color: #fff;
          margin: 0;
        }
      }
      &__col_link {
        display: flex;
        justify-content: end;
        align-items: start;
        &__link {
          text-decoration: none;
          padding: 8px 16px;
          background-color: #ff6600;
          color: #fff;
          border-radius: 7px;
          font-weight: 400;
          font-size: 25px;
          font-family: "Nunito", sans-serif;
        }
      }
    }
  }
}
