.headerdown {
  background-image: url("../images/1673945007img.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(187, 68, 0, 0.8);
  }
  &__wrapper {
    padding: 48px 0;
    height: 100%;
    &__container {
      margin-top: 120px;
      height: 100%;
      position: relative;
      z-index: 5;
      display: flex;
      align-items: end;
      &__row {
        width: 100%;
        &__col8 {
          &__h1 {
            font-size: 38px;
            color: #fff;
            font-weight: 600;
          }
          &__p {
            font-size: 18px;
            color: #fff;
            font-family: "Nunito", sans-serif;
            margin: 0 0 16px;
          }
          &__link {
            background-color: #ff6600;
            color: #fff;
            font-size: 18px;
            font-family: "Nunito", sans-serif;
            padding: 8px 16px;
            border-radius: 7px;
            text-decoration: none;
          }
        }
      }
    }
  }
}
.detail {
  padding: 40px 0 80px;
  background-color: #f5f4f9;
  &__container {
    &__row {
      &__col8 {
        &__content {
          background-color: #fff;
          padding: 24px;
          border-top-right-radius: 7px;
          border-top-left-radius: 7px;
          &__p {
            color: #5c5776;
            font-size: 14px;
            font-family: "Nunito", sans-serif;
          }
        }
        &__links {
          background-color: #fff;
          padding: 24px;
          border-bottom-left-radius: 7px;
          border-bottom-right-radius: 7px;
          border-top: 2px solid #f5f4f9;
          &__list {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;

            &__item {
              margin-right: 8px;
              width: 40px;
              height: 40px;
              border-radius: 7px;
              &:nth-child(1) {
                background-color: #3c5a99;
              }
              &:nth-child(2) {
                background-color: #1da1f2;
              }
              &:nth-child(3) {
                background-color: #d34836;
              }
              &:nth-child(4) {
                background-color: #04b2e5;
              }
              &:nth-child(5) {
                background-color: #21b632;
              }
              &__link {
                text-decoration: none;
                color: #fff;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
      &__col4 {
        margin-top: -250px;
        position: relative;
        z-index: 20;
        &__card {
          background-color: #fff;
          border-radius: 7px;
          margin-bottom: 24px;
          padding: 24px;
          &__img {
            width: 100%;
            height: 100%;
            border-radius: 7px;
          }
          &__duration {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 12px;
            &__icon {
              font-size: 16px;
              color: #21b632;
              font-weight: 600;
            }
            &__p {
              margin: 0 0 0 4px;
              color: #21b632;
              font-size: 14px;
              font-family: "Nunito", sans-serif;
              font-weight: 600;
            }
          }
        }
        @media (max-width: 991px) {
          margin: 24px 0 0 0;
          z-index: 0;
        }
      }
    }
  }
}
