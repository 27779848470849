.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  &__first {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    & > .container {
      & > .row {
        & > .col_8 {
          & > .flex {
            display: flex;
            @media (max-width: 576px) {
              justify-content: center;
              border-top: 1px solid rgba(255, 255, 255, 0.1);
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }
            & > .social_links {
              & > .flex {
                display: flex;
                list-style: none;
                margin: 0;
                padding: 0;
                & > .list_item {
                  border-left: 1px solid rgba(255, 255, 255, 0.1);
                  padding: 9px 15px;
                  & > .list_item_link {
                    width: 100%;
                    color: rgba(255, 255, 255, 0.7);
                  }
                }
              }
            }
            .flag {
              &__list {
                display: flex;
                list-style: none;
                margin: 0;
                padding: 0;
                height: 100%;
                &__item {
                  border-left: 1px solid rgba(255, 255, 255, 0.1);
                  border-right: 1px solid rgba(255, 255, 255, 0.1);
                  display: flex;
                  align-items: center;
                  position: relative;
                  &__link {
                    border: 0;
                    background-color: transparent;
                    padding: 9px 18px;
                    text-decoration: none;
                    cursor: pointer;
                    &__image {
                      width: 20px;
                      height: 12px;
                      margin-right: 5px;
                      @media (max-width: 293px) {
                        width: 12px;
                      }
                    }
                    &__text {
                      color: white;
                      text-transform: uppercase;
                      font-size: 14px;
                      font-weight: 400;
                      font-family: "Nunito", sans-serif;
                      @media (max-width: 293px) {
                        font-size: 10px;
                      }
                    }
                  }
                  &__dropdown {
                    width: 190px;
                    background-color: #fff;
                    position: absolute;
                    left: 10px;
                    bottom: -120px;
                    border-radius: 10px;
                    &__list {
                      list-style: none;
                      padding: 0;
                      margin: 0;
                      &__item {
                        padding: 8px 32px;
                        cursor: pointer;
                        transition: 300ms all ease-in;
                        &__links {
                          text-decoration: none;
                          &__image {
                            width: 25px;
                            height: 15px;
                          }
                          &__text {
                            text-transform: uppercase;
                            margin-left: 10px;
                            text-decoration: none;
                            color: #5c5776;
                          }
                        }
                        &:hover {
                          background-color: #e3e3e3;
                          border-radius: 7px;
                        }
                      }
                    }
                    @media (max-width: 511px) {
                      left: -150px;
                    }
                  }
                }
              }
            }
          }
        }
        .col_4 {
          align-items: center;
          &__list {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: end;
            align-items: center;
            list-style: none;
            margin: 0;
            padding: 0;
            &__item {
              border-left: 1px solid rgba(255, 255, 255, 0.1);
              border-right: 1px solid rgba(255, 255, 255, 0.1);
              padding: 9px 18px;
              &__links {
                text-decoration: none;
                text-align: center;
                color: #fff;
                &__text {
                  margin-left: 5px;
                  color: white;
                  font-size: 14px;
                  font-family: "Nunito", sans-serif;
                  @media (max-width: 993px) {
                    display: none;
                  }
                }
              }
            }
            @media (max-width: 576px) {
              justify-content: center;
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }
  }
  &__second {
    // border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &__custom__container {
      padding: 16px 12px;
      &__custom__row {
        &__col_2 {
          display: flex;
          transition: 300ms all es;
          align-items: center;
          &__link {
            text-decoration: none;
            &__image {
              width: 120px;
              height: 40px;
            }
          }
        }
        &__col_10 {
          &__list {
            display: flex;
            justify-content: end;
            list-style: none;
            margin: 0;
            padding: 0;
            &__item {
              padding: 16px 0;
              position: relative;
              &__link {
                text-decoration: none;
                color: #fff;
                font-size: 14px;
                padding: 5px 15px;
                transition: 300ms all ease-in;
                text-transform: capitalize;
                &:hover {
                  color: #5c5776;
                }
              }
              &__link_contact {
                text-decoration: none;
                color: #fff;
                font-size: 14px;
                padding: 10px 12px;
                background-color: #e87813;
                border-radius: 7px;
                margin: 8px 0;
                transition: 300ms all ease-in;
                text-transform: capitalize;

                &:hover {
                  background-color: #dd6904;
                }
              }
              &__dropdown {
                background-color: #fff;
                display: flex;
                flex-direction: column;
                position: absolute;
                left: 15px;
                width: max-content;
                padding: 10px 15px;
                border-radius: 7px;
                opacity: 0;
                visibility: hidden;
                transform: translateY(20px);
                transition: 300ms all ease-in;
                margin-top: 5px;
                &__link {
                  text-decoration: none;
                  color: #5c5776;
                  font-size: 14px;
                  transition: 300ms all ease-in;
                  margin-bottom: 10px;
                  &:hover {
                    color: #e87813;
                  }
                }
              }
              &:hover {
                .dropdown {
                  opacity: 1;
                  visibility: visible;
                  transform: translateY(0);
                }
              }
            }
          }
          @media (max-width: 992px) {
            display: none;
          }
        }
        &__col_menu {
          display: none;
          @media (max-width: 992px) {
            display: block;
          }
        }
        &__col_tel {
          display: none;
          @media (max-width: 992px) {
            display: flex;
            align-items: center;
            justify-content: end;
            &__link {
              color: #fff;
              background-color: rgba(255, 255, 255, 0.1);
              border-radius: 7px;
              width: 35px;
              height: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        @media (max-width: 576px) {
          // display: flex;
        }
      }
    }
  }
}
