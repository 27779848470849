.col3 {
  &__search {
    padding: 24px;
    background-color: #fff;
    border-radius: 7px;
    transition: 300ms all ease-in;
    display: flex;
    &__input {
      width: 100%;
      border: 1px solid #ededf5;
      outline: 0;
      padding: 6px 0 6px 12px;
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
    }
    &__button {
      background-color: #e87813;
      color: #fff;
      padding: 6px 12px;
      border: 0;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
    }
    &:hover {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
  &__selected {
    background-color: #fff;
    margin-top: 24px;
    border-radius: 7px;
    &__title {
      padding: 18px 24px;
      border-bottom: 1px solid #ededf5;
      &__h3 {
        font-size: 18px;
        color: #18113c;
        font-weight: 700;
        margin: 0;
      }
    }
    &__list {
      padding: 24px;
      &__card {
        display: flex;
        margin-bottom: 10px;
        &__image {
          width: 30%;
          height: 64px;
          &__img {
            border-radius: 7px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        &__body {
          width: 70%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 12px;
          &__link {
            text-decoration: none;
            color: #5c5776;
            font-size: 16px;
            font-family: "Nunito", sans-serif;
            font-weight: 700;
            transition: 300ms all ease-in;
            &:hover {
              color: #e87813;
            }
          }
          &__span {
            color: #a8a3b0;
            font-size: 12px;
            font-family: "Nunito", sans-serif;
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    margin-top: 24px;
  }
}
