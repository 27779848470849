@use "../../scss/abstracts/" as *;
@use "sass:math";

.sidebar {
  @include flex(stretch, flex-start);
  flex-direction: column;
  color: $txtColor;
  width: $sideBarWidth;
  background-color: $mainBg;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 100;

  @include tablet {
    width: 100vw;
    background-color: $mainColor;
    color: $white;
    z-index: 1;
  }

  &__logo {
    @include flex();
    height: $sideBarLogoHeight;

    @include tablet {
      justify-content: space-between;
      height: math.div($sideBarLogoHeight, 1.8);
      padding: 0 $mobileSpacing;
      margin-bottom: $mobileSpacing;
    }

    img {
      --size: 55px;
      width: var(--size);
      height: var(--size);
    }

    .sidebar-close {
      cursor: pointer;
      display: none;

      i {
        font-size: 2rem;
      }

      @include tablet {
        display: block;
      }
    }
  }

  &__menu {
    @include flex(stretch, flex-start);
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    &__item {
      @include flex(center, flex-start);
      position: relative;
      margin-bottom: 2rem;
      padding-left: 20%;
      transition: color 0.3s ease-in-out;

      @include tablet {
        padding-left: $mobileSpacing;
      }

      &:hover {
        color: $mainColor;

        @include tablet {
          color: $orange;
        }
      }

      &.active {
        font-weight: 700;
        color: $mainColor;

        @include tablet {
          color: $orange;
        }

        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 5px;
          left: 0;
          top: 0;
          background-color: $mainColor;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;

          @include tablet {
            background-color: $orange;
          }
        }
      }

      &__icon {
        --size: 40px;
        width: var(--size);
        height: var(--size);
        margin-right: 3rem;
        @include flex(center, center);

        @include tablet {
          margin-right: 1rem;
        }

        i {
          font-size: 1.9rem;
        }
      }

      &:last-child {
        margin-top: auto;
        cursor: pointer;
      }
    }
  }
}
.accordion__body {
  padding-left: 76px;
  &__ul {
    margin-left: 48px;
    &__li {
      text-transform: capitalize;
      font-size: 16px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}

.accordion-button {
  border: none;
  padding-left: 76px;
  background: white !important;
  font-size: 18px;
  color: #4e4d55;
  font-weight: 400;

  &:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(255, 0, 221, 0) !important;
    border: none;
  }

  .setting__icon::before {
    font-size: 38px;
    color: #4e4d55;
    margin-right: 48px;
  }
}

.headerdown__language {
  &__list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    &__item {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      position: relative;
      &__link {
        border: 0;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 10px;
        padding: 9px 18px;
        text-decoration: none;
        cursor: pointer;
        &__image {
          width: 20px !important;
          height: 12px !important;
          margin-right: 5px;
          @media (max-width: 293px) {
            width: 12px;
          }
        }
        &__text {
          color: #000;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 400;
          font-family: "Nunito", sans-serif;
          @media (max-width: 293px) {
            font-size: 10px;
          }
        }
      }
      &__dropdown {
        width: 190px;
        background-color: #fff;
        position: absolute;
        right: 0;
        top: 60px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        &__list {
          list-style: none;
          padding: 0;
          margin: 0;
          &__item {
            padding: 8px 32px;
            cursor: pointer;
            transition: 300ms all ease-in;
            &__links {
              text-decoration: none;
              display: flex;
              align-items: center;
              &__image {
                width: 25px !important;
                height: 15px !important;
              }
              &__text {
                text-transform: uppercase;
                margin-left: 10px;
                text-decoration: none;
                color: #5c5776;
              }
            }
            &:hover {
              background-color: #e3e3e3;
              border-radius: 7px;
            }
          }
        }
        @media (max-width: 511px) {
          // left: -150px;
        }
      }
    }
  }
}
