.card {
  margin-top: 25px;
  height: 305px;
  &__link {
    &__item {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      &__image {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        &__img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
          transition: 300ms all ease-in;
          filter: brightness(65%);
          &:hover {
            transform: scale(1.2);
            filter: brightness(50%);
          }
        }
      }
      &__title {
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 15px 25px;
        background-color: rgba(40, 40, 52, 0.45);
        &__txt {
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          margin: 0;
          @media (max-width: 992px) {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
