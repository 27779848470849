.blogs {
  padding: 40px 0 80px;
  background-color: #f5f4f9;
  &__container {
    &__row {
      &__col9 {
        &__row {
          &__col12 {
            margin-bottom: 26px;
            background-color: #fff;
            border-radius: 7px;
            &__row {
              &__col4 {
                padding: 0;
                &__image {
                  width: 100%;
                  height: 250px;
                  &__img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-top-left-radius: 7px;
                    border-bottom-left-radius: 7px;
                  }
                }
              }
              &__col8 {
                &__card {
                  padding: 24px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: start;
                  height: 100%;
                  flex-wrap: nowrap;
                  &__link {
                    text-decoration: none;
                    &__h3 {
                      color: #18113c;
                      font-size: 20px;
                      font-weight: 500;
                      transition: 300ms all ease-in;
                      &:hover {
                        color: #e87813;
                      }
                    }
                  }
                  &__p {
                    display: block;
                    color: #5c5776;
                    font-size: 16px;
                    font-family: "Nunito", sans-serif;
                  }

                  &__span {
                    color: #18113c;
                    font-size: 14px;
                    font-family: "Nunito", sans-serif;
                  }
                }
              }
            }
          }
        }
        &__pagination {
          margin-top: 24px;
        }
      }
    }
  }
}
