.footer {
  background-color: #282834;

  &__first {
    padding: 48px 0;
    &__container {
      &__row {
        &__col_main {
          &__list {
            list-style: none;
            margin: 0;
            padding: 0;
            &__item {
              margin-bottom: 16px;
              &__link {
                text-decoration: none;
                color: rgba(255, 255, 255, 0.4);
                transition: 300ms all ease-in;
                &__image {
                  width: 120px;
                  height: 40px;
                }
                &:hover {
                  color: #e87813;
                }
              }
              &__list {
                display: flex;
                list-style: none;
                margin: 0;
                padding: 0;
                gap: 16px;
                &__item {
                  background-color: rgba(255, 255, 255, 0.05);
                  border-radius: 7px;
                  width: 35px;
                  height: 36px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transition: 300ms all ease-in;
                  cursor: pointer;
                  &__link {
                    text-decoration: none;
                    color: #fff;
                    font-size: 16px;
                  }
                  &:hover {
                    background-color: #e87813;
                  }
                }
              }
            }
          }
        }
        &__col {
          &__heading {
            color: #fff;
            font-size: 18px;
            margin-bottom: 20px;
            text-transform: capitalize;
          }
          &__list {
            list-style: none;
            margin: 0;
            padding: 0;
            &__item {
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              &__right {
                color: rgba(255, 255, 255, 0.4);
                font-size: 18px;
              }
              &__link {
                text-decoration: none;
                text-transform: capitalize;
                color: rgba(255, 255, 255, 0.4);
                font-size: 14px;
                transition: 300ms all ease-in;
                &:hover {
                  color: #e87813;
                }
              }
              &__button {
                background-color: transparent;
                border: 0;
                color: rgba(255, 255, 255, 0.4);
                margin-left: 5px;
                text-align: end;
                transition: 300ms all ease-in;
                &:hover {
                  color: #fff;
                }
              }
            }
          }
          @media (max-width: 991px) {
            margin-top: 16px;
          }
        }
      }
    }
  }
  &__second {
    padding: 12px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    &__container {
      &__row {
        text-align: center;
        &__text {
          color: #fff;
          font-size: 14px;
          &__link {
            text-decoration: none;
            color: #fff;
            font-size: 18px;
            transition: 300ms all ease-in;
            &:hover {
              color: rgba(255, 255, 255, 0.5);
            }
          }
        }
      }
    }
  }
  &__back {
    z-index: 1000;
  }
}
