.detail {
  padding: 40px 0 80px;
  background-color: #f5f4f9;
  &__container {
    &__row {
      &__col9 {
        &__card {
          padding: 24px;
          background-color: #fff;
          border-radius: 7px;
          transition: 300ms all ease-in;
          &__image {
            width: 100%;
            &__img {
              width: 100%;
              border-radius: 7px;
            }
          }
          &__title {
            margin-top: 24px;
            &__h2 {
              font-size: 28px;
              color: #18113c;
              margin-bottom: 8px;
              font-weight: 600;
            }
          }
          &__date {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            &__icon {
              font-size: 18px;
              color: #e87813;
            }
            &__p {
              margin: 0 0 0 6px;
              font-size: 16px;
              font-family: "Nunito", sans-serif;
              color: #5c5776;
              font-weight: 600;
            }
          }
          &__desc {
            &__p {
              color: #5c5776;
              font-size: 15px;
              font-family: "Nunito", sans-serif;
            }
          }
          &:hover {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }
        }
      }
    }
  }
}
