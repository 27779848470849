.about {
  padding: 40px 0 80px 0;
  background-color: #f5f4f9;
  &__container {
    &__title {
      text-align: center;
      color: #18113c;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    &__sub_title {
      text-align: center;
      color: #5c5776;
      font-size: 18px;
      font-family: "Nunito", sans-serif;
      margin-bottom: 16px;
      font-weight: 600;
    }
    &__desc {
      font-size: 16px;
      font-family: "Nunito", sans-serif;
      color: #5c5776;
    }
  }
}

.chooseus {
  padding: 60px 0 80px 0;
  background-color: #f5f4f9;
  &__container {
    &__title {
      margin: 0 0 24px;
      padding: 0 0 48px;
      &__h2 {
        color: #18113c;
        font-size: 32px;
        margin-bottom: 10px;
        font-weight: 600;
      }
      &__p {
        color: #5c5776;
        font-size: 16px;
        font-family: "Nunito", sans-serif;
        margin: 0;
      }
    }
    &__row {
      &__col7 {
        &__row {
          & > :nth-child(2) {
            margin-top: 48px;
            @media (max-width: 992px) {
              margin-top: 0;
            }
          }
          &__col {
            &__card {
              padding: 24px;
              border-radius: 7px;
              background-color: #fff;
              margin-bottom: 24px;
              &__card_body {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &__image {
                  width: 48px;
                  height: 48px;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  &__img {
                    width: 50%;
                    height: 50%;
                  }
                }
                &__title {
                  &__h3 {
                    font-size: 20px;
                    color: #18113c;
                    margin-bottom: 6px;
                    font-weight: 500;
                    text-align: center;
                  }
                }
                &__desc {
                  text-align: center;
                  &__p {
                    color: #43414e;
                    font-size: 14px;
                    font-family: "Nunito", sans-serif;
                  }
                }
              }
              &:hover {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              }
            }
          }
        }
      }
      &__col5 {
        position: relative;
        &__image {
          width: 100%;
          &::before {
            content: "";
            width: 93%;
            height: 95%;
            position: absolute;
            top: -40px;
            left: 40px;
            border: 3px solid #e87813;
            border-radius: 7px;
            border-top-right-radius: 150px;
          }
          &__img {
            position: relative;
            width: 90%;
            height: 438px;
            object-fit: cover;
            border-top-right-radius: 150px;
          }
        }
      }
      @media (max-width: 992px) {
        &__col5 {
          display: none;
        }
      }
    }
  }
}

.usercount {
  background-image: url("../images/contactImage.jpg");
  background-position: center;
  background-size: cover;
  padding: 40px 0 80px 0;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    z-index: 0;
    // background: rgba(105, 100, 247, 0.9) !important;
    background-color: rgba(187, 68, 0, 0.8);
  }
}
