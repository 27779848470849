.home {
  &__cover {
    width: 100%;
    height: 100vh;
    position: relative;
    top: 0;
    left: 0;
    z-index: 10;

    .slick-list {
      height: 100%;
    }
    .slick-slide :first-child {
      height: 100%;
    }

    .slick-track {
      height: 100%;
    }
    @media (max-width: 768px) {
      height: 497px;
    }
    img {
      width: 100%;
      height: 100vh !important;
      object-position: center;
      object-fit: cover;
      filter: brightness(40%);
      @media (max-width: 768px) {
        object-position: 55% 50%;
        height: 85vh !important;
      }
    }
    @media (max-width: 768px) {
      height: 85vh !important;
    }
  }
  &__slidecontent {
    position: absolute;
    top: 36%;
    width: 100%;
    z-index: 20;
    display: flex;
    justify-content: center;
    &__wrapper {
      width: 60%;
      &__title {
        text-align: center;
        &__heading {
          color: #fff;
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          font-size: 50px;
          @media (max-width: 1021px) {
            font-size: 35px;
          }
        }
        &__desc {
          font-size: 17px;
          color: rgba(255, 255, 255, 0.8);
        }
      }
      &__search {
        width: 100%;
        display: flex;
        &__input {
          width: 70%;
          height: 54px;
          padding: 6px 12px;
          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px;
          border: 0;
          outline: 0;
          &::placeholder {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
        &__button {
          width: 30%;
          height: 54px;
          padding: 6px 12px;
          border: 0;
          background-color: #e87813;
          color: #fff;
          border-top-right-radius: 7px;
          border-bottom-right-radius: 7px;
        }
        @media (max-width: 992px) {
          display: flex;
          flex-direction: column;
          &__input {
            width: 100%;
            border-radius: 7px;
          }
          &__button {
            width: 100%;
            border-radius: 7px;
            margin-top: 10px;
          }
        }
      }
      &__service {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 24px;
        &__link {
          width: 90px;
          height: 90px;
          text-decoration: none;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          gap: 6px;
          text-align: center;
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 7px;
          background-color: rgba(0, 0, 0, 0.05);
          transition: 300ms all ease-in;
          svg {
            font-size: 20px;
          }
          small {
            font-size: 12px;
          }
          &:hover {
            background-color: #e87813;
          }
          @media (max-width: 520px) {
            width: 56px;
            svg {
              font-size: 16px;
            }
            small {
              font-size: 8px;
            }
          }
        }
      }
      @media (max-width: 768px) {
        width: 95%;
      }
    }
    @media (max-width: 591px) {
      top: 26%;
    }
  }
  &__specialization_section {
    margin-top: 40px;
    &__container {
      &__title {
        margin-bottom: 32px;
        font-family: "Poppins", sans-serif;
        font-size: 32px;
        font-weight: 600;
        color: #18113c;
        text-transform: capitalize;
      }
    }
  }
  &__engineer_section {
    margin-top: 40px;
    &__container {
      &__title {
        margin-bottom: 32px;
        font-family: "Poppins", sans-serif;
        font-size: 32px;
        font-weight: 600;
        color: #18113c;
        text-transform: capitalize;
      }
    }
  }
  &__ndtservices {
    margin-top: 40px;

    &__container {
      &__title {
        margin-bottom: 32px;
        font-family: "Poppins", sans-serif;
        font-size: 32px;
        font-weight: 600;
        color: #18113c;
        text-transform: capitalize;
      }
    }
  }
  &__usercount {
    margin-top: 96px;
    background-image: url("../images/pattern2.png");
    background-position: center;
    background-size: cover;
    padding: 96px 0;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: block;
      z-index: 0;
      background: rgba(208, 208, 241, 0.8) !important;
    }
  }
  &__feedback {
    padding: 40px 0 80px 0;
    background-color: #f5f4f9;
    &__container {
      &__title {
        font-family: "Poppins", sans-serif;
        font-size: 32px;
        font-weight: 600;
        color: #18113c;
        margin-bottom: 8px;
      }
      &__desc {
        font-size: 18px;
        font-family: "Nunito", sans-serif;
        color: #5c5776;
        margin-bottom: 48px;
      }
    }
  }
  &__news {
    padding: 40px 0 80px 0;
    background-color: #f5f4f9;
    &__container {
      &__title {
        font-family: "Poppins", sans-serif;
        font-size: 32px;
        font-weight: 600;
        color: #18113c;
        margin-bottom: 8px;
        text-transform: capitalize;
      }
      &__desc {
        font-size: 18px;
        font-family: "Nunito", sans-serif;
        color: #5c5776;
        margin-bottom: 48px;
      }
    }
  }
  &__partners {
    padding: 40px 0 80px 0;
    &__container {
      &__title {
        font-family: "Poppins", sans-serif;
        font-size: 32px;
        font-weight: 600;
        color: #18113c;
        margin-bottom: 8px;
      }
      &__desc {
        font-size: 18px;
        font-family: "Nunito", sans-serif;
        color: #5c5776;
        margin-bottom: 48px;
      }
    }
  }
}
