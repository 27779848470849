.partners {
  &__link {
    text-decoration: none;
    cursor: pointer;
    &__border {
      margin-right: 25px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      &__image {
        height: 50px;
      }
    }
  }
}
