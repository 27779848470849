.usercount {
  position: relative;
  z-index: 10;
  &__container {
    &__row {
      &__col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__image {
          width: 64px;
          height: 64px;
          // background-color: #e87813;
          border-radius: 7px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 16px;
          &__img {
            width: 30px;
            height: 30px;
          }
        }
        &__h5 {
          margin-bottom: 4px;
          font-size: 16px;
          // color: #18113c;
        }
        &__h2 {
          margin: 16px 0 0 0;
          font-size: 35px;
          // color: #18113c;
        }
      }
    }
  }
}
