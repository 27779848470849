@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
.dashboard {
  .center {
    text-align: center;
  }

  .dash__button {
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    transition: all 0.5s ease;
    border: none;
    background: #ffd800;
    border-radius: 30px;
    text-transform: uppercase;
    box-sizing: border-box;
    padding: 15px 40px;
    font-weight: 400;
    font-size: 13px;
    cursor: pointer;
  }

  .dash__button:hover {
    color: #ffffff;
    background: #222222;
    -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  }

  .slider-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .slide {
    // height: 30vh;
    background-size: cover !important;
  }

  .slide::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(transparent),
      to(rgba(0, 0, 0, 0.9))
    );
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    bottom: 0;
    left: 0;
  }

  .previousButton,
  .nextButton {
    display: none !important;
  }

  .slider-content {
    text-align: center;
  }

  .slider-content .inner {
    padding: 0 70px;
    box-sizing: border-box;
    position: absolute;
    z-index: 9999;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  //   .slider-content * {
  //     pointer-events: none;
  //   }

  .slider-content .inner .dash__button {
    transition-delay: 0s;
  }

  .slider-content .inner h1 {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #ffffff;
    font-size: 48px;
    line-height: 1;
  }

  .slider-content .inner p {
    color: #ffffff;
    font-size: 14px;
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
  }

  .slider-content section {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }

  .slider-content section span {
    color: #ffffff;
  }

  .slider-content section span {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    display: inline-block;
    text-align: left;
    line-height: 1.4;
    vertical-align: middle;
    margin-left: 10px;
  }

  .slider-content section img {
    width: 40px;
    height: 40px;
    // border: solid 2px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    vertical-align: middle;
  }

  .slider-content section span strong {
    color: #ffffff;
    font-size: 14px;
    display: block;
  }

  @media (max-height: 500px) {
    .slider-wrapper,
    .slide {
      // height: calc(100vh - 75px);
      height: 100%;
    }
  }

  @media (max-width: 640px) {
    .slider-wrapper,
    .slide {
      // height: calc(80vh - 75px);
      height: 100%;
    }
  }

  @media (max-height: 600px) {
    .slider-content .inner h1 {
      font-size: 32px;
    }
  }

  @media (max-width: 640px) {
    .slider-content .inner h1 {
      font-size: 32px;
    }
  }

  .slide h1 {
    transition: all 0.3s ease;
    transform: translateY(-20px);
    opacity: 0;
  }

  .slide .dash__button {
    transition: all 0.3s ease;
    transform: translateY(20px);
    opacity: 0;
  }

  .slide p {
    transition: all 0.3s ease;
    transform: translateY(20px);
    opacity: 0;
  }

  .slide section * {
    transition: all 0.3s ease;
  }

  .slide section img {
    transform: translateX(-10px);
    opacity: 0;
  }

  .slide section span {
    transform: translateY(-10px);
    opacity: 0;
  }

  .slide section span strong {
    transform: translateY(10px);
    opacity: 0;
  }

  .slide.animateIn.previous h1,
  .slide.current h1,
  .slide.animateIn.next h1,
  .slide.animateIn.previous .dash__button,
  .slide.current .dash__button,
  .slide.animateIn.next .dash__button,
  .slide.animateIn.previous p,
  .slide.current p,
  .slide.animateIn.next p,
  .slide.animateIn.previous section *,
  .slide.current section *,
  .slide.animateIn.next section * {
    transform: translateX(0);
    transition-delay: 0.9s;
    opacity: 1;
  }

  .slide.animateIn.previous p,
  .slide.animateIn.next p {
    transition-delay: 1.1s;
  }

  .slide.animateIn.previous .dash__button,
  .slide.animateIn.next .dash__button {
    transition-delay: 1.3s;
  }

  .slide.animateIn.previous section img,
  .slide.animateIn.next section img {
    transition-delay: 1.3s;
  }

  .slide.animateIn.previous section span,
  .slide.animateIn.next section span {
    transition-delay: 1.4s;
  }

  .slide.animateIn.previous section span strong,
  .slide.animateIn.next section span strong {
    transition-delay: 1.5s;
  }

  .slide.animateOut h1 {
    transition-delay: 0.3s;
  }

  .slide.animateOut p {
    transition-delay: 0.2s;
  }

  .slide.animateOut section span {
    transition-delay: 0.1s;
  }

  .slide.animateOut section span strong {
    transition-delay: 0s;
  }

  .dashboard {
    width: 100%;
    height: 100vh;
  }
}

.dashboard {
  width: 100%;
  height: 100vh;
}
