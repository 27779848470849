@use "./abstracts/" as *;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  .admin-wrapper {
    font-family: $fontFamily;
    color: $txtColor;
    font-size: $fontSizeNormal;
    line-height: 1.5;
    background-color: $bodyBg;
    overflow-x: hidden;

    a {
      text-decoration: none;
      color: unset;
    }

    img {
      width: 100%;
    }

    ul,
    li {
      list-style-type: none;
    }
  }

  .mb {
    margin-bottom: $spacing;

    @include tablet {
      margin-bottom: $mobileSpacing;
    }
  }

  .title {
    font-size: $fontSizeMedium;
    font-weight: 700;
  }

  .txt-success {
    color: $green;
  }

  .txt-danger {
    color: $red;
    img {
      width: 100%;
    }
    a {
      text-decoration: none;
      color: unset;
    }

    ul,
    li {
      list-style-type: none;
    }
    .mb {
      margin-bottom: $spacing;

      @include tablet {
        margin-bottom: $mobileSpacing;
      }
    }

    .title {
      font-size: $fontSizeMedium;
      font-weight: 700;
    }

    .txt-success {
      color: $green;
    }

    .txt-danger {
      color: $red;
    }
  }
}
