.headerdown {
  background-image: url("../images/headerdown.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 300px;
  top: 0;
  left: 0;
  z-index: 10;
  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(187, 68, 0, 0.8);
  }
  &__wrapper {
    padding: 48px 0;
    height: 100%;
    &__text {
      height: 100%;
      position: relative;
      z-index: 5;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      & > h1 {
        color: #fff;
        margin: 0 0 12px 0;
        font-weight: 700;
      }
      & > ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        & > :nth-child(2) {
          color: #fff;
          &::before {
            content: "/";
            padding: 0 8px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.8);
          }
        }
        & > li {
          font-size: 14px;
          & > a {
            text-decoration: none;
            color: rgba(255, 255, 255, 0.8);
            font-size: 14px;
            font-family: "Nunito", sans-serif;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
