.message {
  position: fixed;
  z-index: 1000;
  left: 30px;
  bottom: 20px;
  &__icon {
    position: relative;
    width: max-content;
    cursor: pointer;
    &__avatar {
      position: relative;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: rgb(232, 120, 19);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      &__image {
        color: #fff;
        font-size: 24px;
      }
    }
    &__pulse {
      width: 20px;
      height: 20px;
      background-color: rgb(232, 120, 19);
      border-radius: 50%;
      position: absolute;
      top: 32%;
      left: 32%;
      transform: translate(-50%, -50%);
      animation: 2000ms infinite alternate identifier;
      animation-delay: 0.5s;
      opacity: 0.2;
      z-index: 0;
      @keyframes identifier {
        from {
          transform: scale(1);
        }
        to {
          transform: scale(4);
        }
      }
    }
    &__notify {
      position: absolute;
      z-index: 20;
      top: -5px;
      right: -5px;
      background-color: red;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s ease all;
      animation: 1000ms infinite jump;
      &__count {
        color: #fff;
        font-size: 12px;
      }
      @keyframes jump {
        from {
          transform: translate(0);
        }
        to {
          transform: translate(0, -10px);
        }
      }
    }
  }
  &__dropdown {
    position: relative;
    bottom: 20px;
    &__header {
      padding: 25px 15px;
      position: relative;
      background-color: #e87813;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      &__close {
        position: absolute;
        right: 10px;
        top: 0;
        cursor: pointer;
        color: #fff;
        font-size: 20px;
      }
      &__title {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
      &__desc {
        color: #fff;
        font-size: 12px;
        opacity: 0.9;
        text-align: center;
      }
    }
    &__body {
      &__wrapper {
        background-color: #fff;
        padding: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        &__link {
          text-decoration: none;
          &__item {
            display: flex;
            align-items: center;
            background-color: #f5f7f9;
            padding: 10px 12px;
            margin-bottom: 10px;
            border-radius: 4px;
            &__whatsapp_icon {
              background-color: #10c379;
              width: 60px;
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              &__ico {
                color: #fff;
                font-size: 30px;
              }
            }
            &__linkedin_icon {
              background-color: #0a66c2;
              width: 60px;
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              &__ico {
                color: #fff;
                font-size: 30px;
              }
            }
            &__content {
              margin: 0 0 0 10px;
              &__title {
                color: #212529;
                font-size: 14px;
                font-weight: 500;
                margin: 0;
              }
              &__desc {
                color: 12px;
                margin: 5px 0 0 0;
                font-size: 12px;
                color: #747c84;
              }
              &__online {
                width: max-content;
                background-color: #e87813;
                color: #fff;
                font-size: 10px;
                border-radius: 3px;
                padding: 2px 4px;
                display: flex;
                align-self: flex-start;
              }
            }
          }
        }
      }
    }
  }
}
