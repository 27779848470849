.relative {
  position: relative !important;
  top: 3px;
  &__shape {
    position: absolute;
    bottom: -1px;
    z-index: 15;
    pointer-events: none;
    right: 0;
    left: 0;
    width: 100%;
    color: #282834 !important;
    &__svg {
      -webkit-transform: scale(2);
      transform: scale(2);
      width: 100%;
      height: auto;
      -webkit-transform-origin: top center;
      transform-origin: top center;
    }
  }
}
