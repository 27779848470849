.certificat {
  padding: 40px 0 80px 0;
  background-color: #f5f4f9;
  &__container {
    &__row {
      &__col6 {
        display: flex;
        align-items: center;
        justify-content: start;
        &__check {
          width: 100%;
          display: flex;
          flex-direction: column;
          background-color: #fff;
          border-radius: 7px;
          padding: 24px;
          transition: 30ms all ease-in;
          &__h3 {
            font-size: 24px;
            color: #18113c;
            font-weight: 600;
          }
          &__input {
            border: solid 1px #ededf5;
            border-radius: 7px;
            padding: 10px 15px;
            outline: 0;
            margin-bottom: 18px;
            &::placeholder {
              font-size: 14px;
            }
          }
          &__button {
            border: 0;
            background-color: #e87813;
            padding: 6px 12px;
            width: max-content;
            color: #fff;
            border-radius: 7px;
            transition: 300ms all ease-in;
            &:hover {
              background-color: #d67017;
            }
          }
          &:hover {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }
        }
        &__image {
          width: 100%;
          height: 100%;
          &__img {
            width: 100%;
            height: 100%;
            border-radius: 12px;
          }
        }
        @media (max-width: 991px) {
          margin-top: 24px;
        }
      }
    }
  }
}
