.loadingbox {
  background: #000;
  background: #fff;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__img {
    animation-name: loader;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }
}
@keyframes loader {
  0% {
    width: 300px;
    height: 300px;
  }
  50% {
    width: 200px;
    height: 200px;
  }
  100% {
    width: 300px;
    height: 300px;
  }
}
