.feedbackcard {
  padding: 24px;
  margin-right: 56px;
  background-color: #fff;
  border-radius: 7px;
  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    &__quote {
      font-size: 48px;
      opacity: 0.2;
    }
    &__message {
      font-size: 16px;
      font-family: "Nunito", sans-serif;
      color: #5c5776;
      text-align: center;
    }
    &__logo {
      width: 96px;
      height: 96px;
      border-radius: 50%;
    }
    &__trainer {
      color: #18113c;
      font-size: 18px;
    }
    &__duty {
      font-size: 14px;
      font-family: "Nunito", sans-serif;
      color: #5c5776;
    }
  }
  @media (max-width: 1024px) {
    margin-right: 0;
  }
}
