.newscard {
  padding: 15px;
  margin-right: 25px;
  &__card {
    background-color: #fff;
    border-radius: 7px;
    transition: 300ms all ease-in;
    &__image {
      height: 300px;
      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
      }
    }
    &__body {
      padding: 24px;
      &__title {
        font-size: 16px;
        color: #18113c;
      }
      &__desc {
        font-size: 14px;
        font-family: "Nunito", sans-serif;
        color: #5c5776;
        margin-bottom: 8px;
      }
      &__link {
        color: #e87813;
        font-size: 14px;
        font-family: "Nunito", sans-serif;
        text-decoration: none;
      }
    }
    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }
}
