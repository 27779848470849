.equipment {
  padding: 80px 0 120px;
  background-color: #f5f4f9;
  &__container {
    &__row {
      &__col {
        &__wrapper {
          &__title {
            margin-bottom: 28px;
            &__h2 {
              color: #5c5776;
              font-size: 24px;
              font-family: "Nuniti", sans-serif;
              font-weight: 600;
              margin: 0;
            }
          }
          &__code {
            &__p {
              color: #5c5776;
              font-size: 20px;
              font-family: "Nunito", sans-serif;
              font-weight: 600;
              margin: 8px 0;
            }
            &__span {
              color: #5c5776;
              font-size: 14px;
              font-family: "Poppins", sans-serif;
              font-weight: 400;
            }
          }
          &__desc {
            margin-top: 28px;
            &__p {
              color: #5c5776;
              font-size: 20px;
              font-family: "Nunito", sans-serif;
              font-weight: 600;
              margin-bottom: 8px;
            }
            &__span {
              color: #5c5776;
              font-size: 14px;
              font-family: "Poppins", sans-serif;
              font-weight: 400;
            }
          }
        }
        &:nth-child(2) {
          @media (max-width: 768px) {
            margin-top: 80px;
          }
        }
      }
    }
  }
}
.image {
  width: 100%;
  height: 500px;
  @media (max-width: 1024px) {
    height: 300px;
  }
}
