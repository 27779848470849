.notfound__parent {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background: #1a202c;
  height: 100vh;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  .custom-container {
    &__list {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      gap: 10px;
      margin: 0;
      padding: 0;
      &__item {
        color: #a0aec0;
        font-size: 1.125rem;
      }
      .line {
        font-size: 26px;
      }
    }
  }
}
