.equipments {
  padding: 60px 0 80px;
  background-color: #f5f4f9;
  &__container {
    &__row {
      &__col3 {
        &__wrapper {
          &__heading {
            margin-bottom: 20px;
            &__h2 {
              font-size: 22px;
              color: #18113c;
              font-family: "Poppins", sans-serif;
            }
          }
          &__search {
            margin-bottom: 20px;
            display: flex;
            &__input {
              width: 100%;
              display: block;
              padding: 8px 16px;
              border: 1px solid #666;
              border-top-left-radius: 7px;
              border-bottom-left-radius: 7px;
              &:focus {
                border: 0;
                outline: 1px solid #666;
              }
            }
            &__button {
              padding: 8px 12px;
              border: 0;
              border-top-right-radius: 7px;
              border-bottom-right-radius: 7px;
              background-color: #e87813;
              color: #fff;
            }
          }
          &__categories {
            &__list {
              list-style: none;
              margin: 0;
              padding: 0;
              &__item {
                display: flex;
              }
            }
          }
        }
        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
      &__col9 {
        &__row {
          &__col4 {
            margin-bottom: 24px;
            &__card {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 15px;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              border-radius: 7px;
              &__image {
                &__img {
                  width: 100%;
                  height: 300px;
                  cursor: pointer;
                }
              }
              &__title {
                &__h4 {
                  font-size: 20px;
                  color: #18113c;
                  font-family: "Nunito", sans-serif;
                  font-weight: 700;
                }
              }
              &__link {
                margin-top: 12px;
                &__link {
                  font-size: 16px;
                  text-decoration: none;
                  font-family: "Poppins", sans-serif;
                  font-weight: 600;
                  color: #e87813;
                }
              }
            }
          }
        }
      }
    }
  }
}
