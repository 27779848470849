.accreditations {
  padding: 40px 0 80px 0;
  background-color: #f5f4f9;
  &__container {
    &__title {
      text-align: center;
      &__h1 {
        color: #18113c;
        font-size: 40px;
        margin-bottom: 16px;
        font-weight: 700;
        @media (max-width: 320px) {
          font-size: 30px;
        }
      }
    }
    &__row {
      &__col {
        margin-top: 24px;
        &__card {
          background-color: #fff;
          border-radius: 7px;
          transition: 300ms all ease-in;
          &__image {
            width: 100%;
            height: 530px;
            &__img {
              width: 100%;
              height: 100%;
              border-top-right-radius: 7px;
              border-top-left-radius: 7px;
            }
          }
          &__name {
            padding: 24px;
            display: flex;
            &__h4 {
              color: #18113c;
              font-size: 18px;
              font-weight: 600;
              margin: 0;
            }
            &__button {
              border: 0;
              background-color: transparent;
              color: #e87813;
              margin-left: 10px;
              font-size: 18px;
              &:hover {
                color: #dd6904;
              }
            }
          }
          &:hover {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }
        }
      }
    }
  }
}
