.headerdown {
  background-color: #fff;
  padding: 20px 16px;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  &__title {
    &__h2 {
    }
  }
  &__language {
    &__list {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      &__item {
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        position: relative;
        &__link {
          border: 0;
          background-color: transparent;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          border-radius: 10px;
          padding: 9px 18px;
          text-decoration: none;
          cursor: pointer;
          &__image {
            width: 20px !important;
            height: 12px !important;
            margin-right: 5px;
            @media (max-width: 293px) {
              width: 12px;
            }
          }
          &__text {
            color: #000;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 400;
            font-family: "Nunito", sans-serif;
            @media (max-width: 293px) {
              font-size: 10px;
            }
          }
        }
        &__dropdown {
          width: 190px;
          background-color: #fff;
          position: absolute;
          right: 0;
          top: 60px;
          border-radius: 10px;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          &__list {
            list-style: none;
            padding: 0;
            margin: 0;
            &__item {
              padding: 8px 32px;
              cursor: pointer;
              transition: 300ms all ease-in;
              &__links {
                text-decoration: none;
                display: flex;
                align-items: center;
                &__image {
                  width: 25px !important;
                  height: 15px !important;
                }
                &__text {
                  text-transform: uppercase;
                  margin-left: 10px;
                  text-decoration: none;
                  color: #5c5776;
                }
              }
              &:hover {
                background-color: #e3e3e3;
                border-radius: 7px;
              }
            }
          }
          @media (max-width: 511px) {
            // left: -150px;
          }
        }
      }
    }
  }
}
