.contact {
  padding: 40px 0 80px 0;
  background-color: #f5f4f9;
  &__container {
    &__info {
      text-align: center;
      &__row {
        &__col {
          &__card {
            padding: 24px;
            background-color: #fff;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            transition: 300ms all ease-in;
            border: 1px solid #e87813;
            height: 100%;
            &__icon {
              background-color: #e87813;
              width: 45px;
              height: 45px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-size: 20px;
              margin-bottom: 8px;
            }
            &__text {
              &__a {
                text-decoration: none;
                color: rgba(0, 0, 0, 0.5);
                font-size: 16px;
                margin-bottom: 4px;
                cursor: pointer;
                transition: 300ms all ease-in;
                &:hover {
                  color: #e87813;
                }
              }
            }
            &:hover {
              box-shadow: #e87813 0px 7px 29px 0px;
            }
          }
          @media (max-width: 991px) {
            margin-bottom: 24px;
          }
        }
      }
    }
    &__main {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      &__form {
        background-color: #fff;
        padding: 24px;
        border-radius: 7px;
        &__header {
          &__h5 {
            color: #18113c;
            font-size: 25px;
            margin-bottom: 8px;
            font-weight: 600;
          }
          &__p {
            font-size: 16px;
            font-family: "Nunito", sans-serif;
            color: #2e384d;
            margin-bottom: 16px;
          }
        }
        &__fr {
          display: flex;
          flex-direction: column;
          &__label {
            color: #5c5776;
            font-size: 14px;
            font-family: "Nunito", sans-serif;
            margin-bottom: 8px;
          }
          &__input {
            padding: 5px 15px;
            border-radius: 7px;
            border: solid 1px #ededf5;
            outline: 0;
            margin-bottom: 16px;
          }
          &__textarea {
            padding: 5px 15px;
            border-radius: 7px;
            border: solid 1px #ededf5;
            outline: 0;
            margin-bottom: 16px;
          }
          &__button {
            border: 0;
            background-color: #e87813;
            color: #fff;
            padding: 6px 12px;
            border-radius: 7px;
            width: max-content;
          }
        }
      }
    }
  }
}
