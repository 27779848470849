.trainings {
  padding: 40px 0 80px;
  background-color: #f5f4f9;
  &__container {
    &__row {
      &__col9 {
        &__filter {
          padding: 24px;
          background-color: #fff;
          border-radius: 7px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &__select {
            display: flex;
            flex-direction: column;
            &__span {
              font-size: 14px;
              font-family: "Nunito", sans-serif;
              margin: 12px 8px 4px 0;
              color: #5c5776;
            }
            &__sel {
              font-size: 14px;
              font-family: "Nunito", sans-serif;
              color: #5c5776;
              padding: 8px 20px 8px;
              border-radius: 7px;
              border: 1px solid #ededf5;
              outline: none;
            }
          }
          &__menu {
            &__list {
              display: flex;
              list-style: none;
              margin: 0;
              padding: 0;
              & > :nth-child(1) {
                border-top-left-radius: 7px;
                border-bottom-left-radius: 7px;
              }
              & > :nth-child(2) {
                border-top-right-radius: 7px;
                border-bottom-right-radius: 7px;
              }
              &__item {
                border: 1px solid #ededf5;
                padding: 8px 15px;
                font-size: 20px;
                color: #5c5776;
                cursor: pointer;
              }
            }
            @media (max-width: 768px) {
              display: none;
            }
          }
        }
        &__row {
          &__col12 {
            // margin-top: 26px;
            &__card {
              display: flex;
              background-color: #fff;
              border-radius: 7px;
              transition: 300ms all ease-in;
              &__image {
                height: 209px;
                width: 30%;
                &__img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                  object-position: center;
                  border-top-left-radius: 7px;
                  border-bottom-left-radius: 7px;
                }
              }
              &__body {
                width: 70%;
                padding: 24px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: start;
                &__link {
                  text-decoration: none;
                  &__h3 {
                    color: #18113c;
                    font-size: 20px;
                    font-weight: 500;
                    cursor: pointer;
                    transition: 300ms all ease-in;
                    &:hover {
                      color: #e87813;
                    }
                  }
                }
                &__p {
                  color: #5c5776;
                  font-size: 16px;
                  font-family: "Nunito", sans-serif;
                }
                &__span {
                  color: #18113c;
                  font-size: 14px;
                  font-family: "Nunito", sans-serif;
                }
              }
              &:hover {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              }
              @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
                &__image {
                  width: 100%;
                  &__img {
                    width: 100%;
                    border-top-right-radius: 7px !important;
                    border-bottom-left-radius: 0 !important;
                  }
                }
              }
            }
          }
        }
        &__pagination {
          margin-top: 24px;
        }
      }
      &__col3 {
        &__search {
          padding: 24px;
          background-color: #fff;
          border-radius: 7px;
          transition: 300ms all ease-in;
          display: flex;
          &__input {
            width: 100%;
            border: 1px solid #ededf5;
            outline: 0;
            padding: 6px 0 6px 12px;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
          }
          &__button {
            background-color: #e87813;
            color: #fff;
            padding: 6px 12px;
            border: 0;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
          }
          &:hover {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }
        }
        &__selected {
          background-color: #fff;
          margin-top: 24px;
          border-radius: 7px;
          &__title {
            padding: 18px 24px;
            border-bottom: 1px solid #ededf5;
            &__h3 {
              font-size: 18px;
              color: #18113c;
              font-weight: 700;
              margin: 0;
            }
          }
          &__list {
            padding: 24px;
            &__card {
              display: flex;
              margin-bottom: 10px;
              &__image {
                width: 80px;
                height: 64px;
                &__img {
                  border-radius: 7px;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
              }
              &__body {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 12px;
                &__link {
                  text-decoration: none;
                  color: #5c5776;
                  font-size: 16px;
                  font-family: "Nunito", sans-serif;
                  font-weight: 700;
                  transition: 300ms all ease-in;
                  &:hover {
                    color: #e87813;
                  }
                }
                &__span {
                  color: #a8a3b0;
                  font-size: 12px;
                  font-family: "Nunito", sans-serif;
                }
              }
            }
          }
        }
      }
    }
  }
}
