.ndtservice {
  padding: 24px;
  border-radius: 7px;
  margin-right: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  &__custom_card {
    display: block;
    &__image {
      width: 100%;
      height: 250px;
      overflow: hidden;
      border-radius: 7px;
      &__img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        filter: brightness(65%);
        transition: 300ms all ease-in;
        &:hover {
          transform: scale(1.1);
          filter: brightness(50%);
        }
      }
    }
    &__title {
      margin-top: 28px;
      &__txt {
        font-size: 18px;
        font-weight: 600;
        color: #18113c;
        margin: 0;
        transition: 300ms all ease-in;
        cursor: pointer;
        &:hover {
          color: #e87813;
        }
      }
    }
    &__desc {
      margin-top: 28px;
      &__txt {
        margin: 0;
        font-size: 16px;
        color: #2e384d;
        font-family: "Nunito", sans-serif;
      }
    }
  }
}
